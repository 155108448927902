// content area
.content {
	@include font-size(16);
  padding: 0 2rem;
  margin: 1rem 0 1.5rem 0;
  @media #{$small} {
		padding: 0;
	}
}

// links
a {
  transition: color .4s;
  color: $color_shark;
  &:link {
  	color: $color_shark;
  }
  &:visited {
  	color: $color_tuatara;
  }
  &:hover {
  	color: $red;
  }
  &:active {
  	transition: color .3s;
  	color: $maroon;
  }
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

// Buttons
%btnextend {
	display: inline-block;
	border: 1px solid;
	border-color: rgba($color_mercury,0.5) rgba($color_bon_jour,0.5) $color_mischka;
	border-radius: 3px;
	text-decoration: none;
	background: $white;
	color: $color_shark;
	&:hover {
		box-shadow: 0 0 1px 0 rgba($color_shark,0.5);
		color: $red;
	}
}

.btn {
	padding: 10px 18px;
	margin: 0 0.5rem;
	margin-bottom: 1rem;
	@extend %btnextend;
}

.btn-back {
	position: absolute;

	@media #{$small} {
		right: 0;
	}
}

// hr break
hr {
	border: 0;
	height: 1px;
	opacity: .25;
  margin: 1em 0;
	background-image: -webkit-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	background-image: -moz-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	background-image: -ms-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
	background-image: -o-linear-gradient(left, $color_gallery, $color_stack, $color_gallery);
}

// Images and figures
img {
  max-width: 100%;
  height: auto;
}

.img-circle {
	border-radius: 50%;
	border: 3px solid $white;
	height: 200px;
	width: 200px;
}

// Lists
ul:not(ul > li > ul) {
	list-style-position: inside;
	padding-left: 0;
}

// SVG Icon Styles
.icon {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    fill: currentColor;

    &.icon--twitter,
    &.icon--github,
    &.icon--message-circle {
        width: 42px;
        height: 42px;
    }
}

// Fonts
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: optional;
    src: local('Raleway'), url(/assets/fonts/raleway-v34-latin-regular.woff2) format('woff2');
}

.animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
}

@keyframes rotateIn {
    from {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }

    to {
        transform-origin: center;
        transform: none;
        opacity: 1;
    }
}
  
.rotateIn {
    animation-name: rotateIn;
}

canvas {
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: linear-gradient(to right, #62bef7, #1672AB);
}

.reference-preview {
    display: flex;
    justify-content: space-between;

    @media #{$small} {
        flex-direction: column;
    }

    img {
        transition: box-shadow .4s;
        &:hover {
            box-shadow: 0 5px 15px 0 rgba($red, 1);
        }
    }

    a+a {
        margin-left: 1rem;

        @media #{$small} {
            margin-top: 1rem;
            margin-left: 0;
        }
    }
}

.lazy {
    opacity: 0;
    will-change: opacity;
    
    // Zero height divs are not loaded by Intersection Observer
    // in Chromium 72+ any more
    min-height: 1px;
}

.loaded {
    animation: fadeIn 0.5s ease-in;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    to {
      opacity: 1;
    }
}

// Gallery
.gallery {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 768px) and (max-width: 991.98px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 992px) and (max-width: 1199.98px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }

    img {
        transition: transform .4s;
        &:hover {
            transform: scale(1.2, 1.2);
        }
    }
}

//@extend-elements
%extend1 {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	font-size: 100%;
}

html {
	@extend %extend1;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	@extend %extend1;
	font-family: $font1;
	background-color: $bodycolor;
}

header {
    display: flex;
    align-items: center;
	justify-content: center;
	
	@media #{$landscape} {
		height: 100vw;
	}

	@media #{$notsmall} {
		height: 100vh;
	}
}

.container {
	max-width: 550px;
	padding: 1rem;
	background-color: $white;
	color: $color_shark;
	border-radius: 3px;
	box-shadow: 0 0 10px 0 rgba($color_shark,0.3);
	@include transition(.5s);
	@media #{$small} {
		box-shadow: none;
	}
	@media #{$medium} {
		width: 90%;
	}

	h1 {
		font-size: 1.4rem;
		font-weight: 300;
	}

	h2 {
		font-size: 1.2rem;
		font-weight: 300;
	}

	h3 {
		font-size: 1rem;
		font-weight: 300;
	}
}

.wrapper {
	max-width: 1024px;
	margin: 4rem auto;
	background: $white;
	color: $color_tuatara;
	padding: 0.5rem;
	border-radius: 3px;
	box-shadow: 0 0 10px 0 rgba($color_shark,0.3);
	@include transition(.5s);
	@media #{$small} {
		margin: 0 auto;
	    box-shadow: none;
	}
	@media #{$medium} {
		width: 90%;
		margin: 2rem auto;
	}
	@media #{$large} {
		width: 75%;
	}
}

// Utilities
.text--center {
	text-align: center;
}
